import React, { Component } from 'react';
import Toolbar from './../../global/Toolbar/Toolbar';
import SideDrawer from './../../global/SideDrawer/SideDrawer';
import Backdrop from './../../global/Backdrop/Backdrop';
import Footer from './../../global/Footer/Footer';

import english from './english.pdf';
import results from './results.pdf';

class Salon extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
        {backdrop}
        <h3 style={{ textAlign: 'center', color: 'black', paddingTop: '10em' }}>Regulament / Regulations</h3>
        <div style={{ marginTop: '5em', textAlign: 'center' }}>
          <iframe src={english} style={{ width: '800px', height: '800px' }} title="english"></iframe>
        </div>

        <h3 style={{ textAlign: 'center', color: 'black', paddingTop: '5em' }}>Rezultate / Results</h3>
        <div style={{ marginTop: '5em', textAlign: 'center' }}>
          <iframe src={results} style={{ width: '800px', height: '800px' }} title="results"></iframe>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Salon;
