import React, { Component } from 'react';
import './AdminSlideshow.css';
import axios from 'axios';

class AdminSlideshow extends Component {
  state = {
    password: '',
    selectedPhotoFile: undefined,
    photos: []
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_slideshow_photos.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ photos: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  photoFileChanged = event => {
    this.setState({
      selectedPhotoFile: event.target.files[0]
    });
  };

  uploadPhotoHandler = event => {
    event.preventDefault();
    if (!this.state.selectedPhotoFile) return alert('Întâi încarcă o fotografie!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie', this.state.selectedPhotoFile);
    axios
      .post(
        `https://fotoclubproarad.ro/api/upload_slideshow_photo.php?admin_pass=${this.state.password}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes! Pentru a vedea fotografia nouă dă refresh la pagină!');
        // window.location.reload();
      })
      .catch(e => {
        // alert('Error: Upload a picture first! / Max limit: 10MB');
        console.log(e.response);
        console.log('Error');
      });
  };

  deletePhoto = id => {
    axios
      .get(
        `https://fotoclubproarad.ro/api/delete_slideshow_photo.php?photo_id=${id}&admin_pass=${this.state.password}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes! Dă refresh la pagină pentru a vedea schimbarea.');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="AdminMembri AdminMembru">
        {/* Luam stilurile din AdminMembri.css si AdminMembru.css*/}
        <p className="honor-title">
          Introdu parola de administrator:
          <br />
          <input onChange={this.changeHandler} name="password" type="password" />
        </p>
        <h3 style={{ paddingLeft: '5em', paddingRight: '5em' }}>
          Bine ai venit pe pagina de administrator a slideshow-ului de pe pagina principală a site-ului. Poți
          încarca o fotografie nouă sau poți șterge o fotografie existentă.
        </h3>

        <p className="honor-title">
          Încarcă o fotografie nouă:
          <form onSubmit={this.uploadPhotoHandler}>
            <input type="file" onChange={this.photoFileChanged} />
            <button type="submit">Confirmă</button>
          </form>
        </p>

        <div className="photos">
          {this.state.photos.map(photo => (
            <div>
              <img src={`https://fotoclubproarad.ro/api/${photo.nume_fotografie}`} alt="foto club pro arad" />
              <span onClick={() => this.deletePhoto(photo.id)}>Șterge fotografia</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AdminSlideshow;
