import React, { Component } from 'react';
import './AdminMembru.css';

import axios from 'axios';

class AdminMembru extends Component {
  state = {
    member: '',
    selectedBigFile: undefined,
    selectedPortraitFile: undefined,
    selectedPhotoFile: undefined,
    password: '',
    honorTitleRo: '',
    honorTitleEng: '',
    descriptionRo: '',
    descriptionEng: '',
    photos: [],
    role: 'membru'
  };

  componentDidMount = () => {
    axios
      .get(`https://fotoclubproarad.ro/api/get_member.php?id=${this.props.match.params.id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ member: response.data[0] });
      })
      .catch(e => {
        console.log(e);
      });

    // Get all photos corresponding to the member with the request below

    axios
      .get(`https://fotoclubproarad.ro/api/get_member_photos.php?id_membru=${this.props.match.params.id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ photos: response.data });
        console.log(this.state.photos);
      })
      .catch(e => {
        console.log(e);
      });
  };

  portraitFileChanged = event => {
    this.setState({
      selectedPortraitFile: event.target.files[0]
    });
  };

  bigFileChanged = event => {
    this.setState({
      selectedBigFile: event.target.files[0]
    });
  };

  changePortraitHandler = event => {
    event.preventDefault();
    if (!this.state.selectedPortraitFile) return alert('Întâi încarcă o fotografie!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie_portret', this.state.selectedPortraitFile);
    axios
      .post(
        `https://fotoclubproarad.ro/api/edit_member_portrait.php?id=${this.props.match.params.id}&admin_pass=${this.state.password}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        // alert('Error: Upload a picture first! / Max limit: 10MB');
        console.log(e.response);
        console.log('Error');
      });
  };

  changePhotoHandler = event => {
    event.preventDefault();
    if (!this.state.selectedBigFile) return alert('Întâi încarcă o fotografie!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie_mare', this.state.selectedBigFile);
    axios
      .post(
        `https://fotoclubproarad.ro/api/edit_member_photo.php?id=${this.props.match.params.id}&admin_pass=${this.state.password}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        // alert('Error: Upload a picture first! / Max limit: 10MB');
        console.log(e.response);
        console.log('Error');
      });
  };

  passwordChangeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  honorTitleRoChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  honorTitleEngChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeHonorTitle = lang => {
    axios
      .get(
        `https://fotoclubproarad.ro/api/edit_member_honor_title.php?id=${
          this.props.match.params.id
        }&admin_pass=${this.state.password}&lang=${lang}&titlu_onorific=${
          lang === 'ro' ? this.state.honorTitleRo : this.state.honorTitleEng
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  roleChange = event => {
    this.setState({ role: event.target.value });
  };

  changeRole = () => {
    axios
      .get(
        `https://fotoclubproarad.ro/api/edit_member_role.php?id=${this.props.match.params.id}&admin_pass=${this.state.password}&role=${this.state.role}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  descriptionChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeDescription = lang => {
    const data = new FormData();
    data.append('descriere', lang === 'ro' ? this.state.descriptionRo : this.state.descriptionEng);

    axios
      .post(
        `https://fotoclubproarad.ro/api/edit_member_description.php?id=${this.props.match.params.id}&admin_pass=${this.state.password}&lang=${lang}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  deletePhoto = id => {
    axios
      .get(
        `https://fotoclubproarad.ro/api/delete_member_photo.php?photo_id=${id}&admin_pass=${this.state.password}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  uploadPhotoHandler = event => {
    event.preventDefault();
    if (!this.state.selectedPhotoFile) return alert('Întâi încarcă o fotografie!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie', this.state.selectedPhotoFile);
    data.append('id_membru', this.props.match.params.id);
    axios
      .post(
        `https://fotoclubproarad.ro/api/upload_member_photo.php?admin_pass=${this.state.password}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        }
      )
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
        // window.location.href = '/projects';
        alert('Succes!');
        // window.location.reload();
      })
      .catch(e => {
        // alert('Error: Upload a picture first! / Max limit: 10MB');
        console.log(e.response);
        console.log('Error');
      });
  };

  photoFileChanged = event => {
    this.setState({
      selectedPhotoFile: event.target.files[0]
    });
  };

  deleteMember = () => {
    let r = window.confirm('Sigur doriți să ștergeți acest membru? Acțiunea este ireversibilă!');
    if (r === true) {
      axios
        .get(
          `https://fotoclubproarad.ro/api/delete_member.php?id=${this.props.match.params.id}&admin_pass=${this.state.password}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Client-ID aa4f70aa066eefe'
            }
            // Authorization: 'Bearer ' + varToken
          }
        )
        .then(response => {
          this.response = { ...response };
          if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
          // window.location.href = '/projects';
          alert('Succes!');
          window.location.href = '/admin/membri';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
    // else {
    //   alert('NOT OK');
    // }
  };

  render() {
    return (
      <div className="AdminMembru">
        <p className="honor-title">
          Introdu parola de administrator:
          <br />
          <input onChange={this.passwordChangeHandler} name="password" type="password" />
        </p>
        <h3>{this.state.member.nume}</h3>
        <span style={{ color: 'red', paddingLeft: '5em', cursor: 'pointer' }} onClick={this.deleteMember}>
          Șterge membrul
        </span>

        <div className="portrait">
          <img src={`https://fotoclubproarad.ro/api/${this.state.member.fotografie_portret}`} alt="" />
        </div>

        <p className="honor-title">
          Schimbă fotografia-portret:
          <form onSubmit={this.changePortraitHandler}>
            <input type="file" onChange={this.portraitFileChanged} />
            <button type="submit">Confirmă</button>
          </form>
        </p>

        <div className="portrait">
          <img src={`https://fotoclubproarad.ro/api/${this.state.member.fotografie_mare}`} alt="" />
        </div>

        <p className="honor-title">
          Schimbă fotografia mare de pe pagina membrului:
          <form onSubmit={this.changePhotoHandler}>
            <input type="file" onChange={this.bigFileChanged} />
            <button type="submit">Confirmă</button>
          </form>
        </p>

        <p className="honor-title">
          Titlu onorific în limba română (Președinte, Secretar, Președinte onorific etc.):{' '}
          <span>{this.state.member.titlu_onorific_ro}</span>
          <form>
            <br />
            <input
              type="text"
              onChange={this.honorTitleRoChange}
              name="honorTitleRo"
              placeholder="Modifică titlul onorific"
            />
            <br />
            <br />
            <button type="button" onClick={() => this.changeHonorTitle('ro')}>
              Confirmă
            </button>
          </form>
        </p>

        <p className="honor-title">
          Titlu onorific în limba engleză: <span>{this.state.member.titlu_onorific_eng}</span>
          <form>
            <br />
            <input
              type="text"
              onChange={this.honorTitleRoChange}
              name="honorTitleEng"
              placeholder="Modifică titlul onorific"
            />
            <br />
            <br />
            <button type="button" onClick={() => this.changeHonorTitle('eng')}>
              Confirmă
            </button>
          </form>
        </p>

        <p className="honor-title">
          Rol: <span>{this.state.member.rol}</span>
          <br />
          <select name="role" onChange={this.roleChange} value={this.state.role}>
            <option value="membru" selected>
              Membru FotoClubPro
            </option>
            <option value="fondator">Membru fondator</option>
            <option value="onoare">Membru de onoare</option>
            <option value="colaborator">Membru colaborator</option>
            <option value="memoriam">In Memoriam</option>
          </select>
          <br />
          <br />
          <button type="button" onClick={this.changeRole}>
            Confirmă
          </button>
        </p>

        <div className="descriere">
          <h4>Descriere în limba română</h4>

          <p>
            {this.state.member.descriere_ro}

            <form>
              <br />
              <textarea
                type="text"
                onChange={this.descriptionChange}
                name="descriptionRo"
                placeholder="Modifică descrierea"
                value={this.state.descriptionRo}
              />
              <br />
              <br />
              <button type="button" onClick={() => this.changeDescription('ro')}>
                Confirmă
              </button>
            </form>
          </p>
        </div>

        <div className="descriere">
          <h4>Descriere în limba engleză</h4>

          <p>
            {this.state.member.descriere_eng}

            <form>
              <br />
              <textarea
                type="text"
                onChange={this.descriptionChange}
                name="descriptionEng"
                placeholder="Modifică descrierea"
                value={this.state.descriptionEng}
              />
              <br />
              <br />
              <button type="button" onClick={() => this.changeDescription('eng')}>
                Confirmă
              </button>
            </form>
          </p>
        </div>

        <p className="honor-title">
          Încarcă o fotografie:
          <form onSubmit={this.uploadPhotoHandler}>
            <input type="file" onChange={this.photoFileChanged} />
            <button type="submit">Confirmă</button>
          </form>
        </p>

        <div className="photos">
          {this.state.photos.map(photo => (
            <div>
              <img src={`https://fotoclubproarad.ro/api/${photo.nume_fotografie}`} alt="fotografi arad" />
              <span onClick={() => this.deletePhoto(photo.id)}>Șterge fotografia</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AdminMembru;
