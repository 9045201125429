import React from 'react';

const FacebookSVG = () => (
  <svg className="social-icon" width="33" height="33" viewBox="0 0 33 33">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 8.25C2.75 6.79131 3.32946 5.39236 4.36091 4.36091C5.39236 3.32946 6.79131 2.75 8.25 2.75H24.75C26.2087 2.75 27.6076 3.32946 28.6391 4.36091C29.6705 5.39236 30.25 6.79131 30.25 8.25V24.75C30.25 26.2087 29.6705 27.6076 28.6391 28.6391C27.6076 29.6705 26.2087 30.25 24.75 30.25H8.25C6.79131 30.25 5.39236 29.6705 4.36091 28.6391C3.32946 27.6076 2.75 26.2087 2.75 24.75V8.25ZM8.25 5.5C7.52065 5.5 6.82118 5.78973 6.30546 6.30546C5.78973 6.82118 5.5 7.52065 5.5 8.25V24.75C5.5 25.4793 5.78973 26.1788 6.30546 26.6945C6.82118 27.2103 7.52065 27.5 8.25 27.5H16.5V17.875H15.125C14.7603 17.875 14.4106 17.7301 14.1527 17.4723C13.8949 17.2144 13.75 16.8647 13.75 16.5C13.75 16.1353 13.8949 15.7856 14.1527 15.5277C14.4106 15.2699 14.7603 15.125 15.125 15.125H16.5V13.0625C16.5 11.7861 17.007 10.5621 17.9095 9.65955C18.8121 8.75703 20.0361 8.25 21.3125 8.25H22.1375C22.5022 8.25 22.8519 8.39487 23.1098 8.65273C23.3676 8.91059 23.5125 9.26033 23.5125 9.625C23.5125 9.98967 23.3676 10.3394 23.1098 10.5973C22.8519 10.8551 22.5022 11 22.1375 11H21.3125C21.0416 11 20.7734 11.0533 20.5232 11.157C20.273 11.2606 20.0456 11.4126 19.8541 11.6041C19.6626 11.7956 19.5106 12.023 19.407 12.2732C19.3033 12.5234 19.25 12.7916 19.25 13.0625V15.125H22.1375C22.5022 15.125 22.8519 15.2699 23.1098 15.5277C23.3676 15.7856 23.5125 16.1353 23.5125 16.5C23.5125 16.8647 23.3676 17.2144 23.1098 17.4723C22.8519 17.7301 22.5022 17.875 22.1375 17.875H19.25V27.5H24.75C25.4793 27.5 26.1788 27.2103 26.6945 26.6945C27.2103 26.1788 27.5 25.4793 27.5 24.75V8.25C27.5 7.52065 27.2103 6.82118 26.6945 6.30546C26.1788 5.78973 25.4793 5.5 24.75 5.5H8.25Z"
    />
  </svg>
);

export default FacebookSVG;
