import './App.css';

import Root from './routes/root/Root/Root';
import Contact from './routes/contact/Contact';
import Salon from './routes/salon/Salon';
import Blog from './routes/blog/Blog';
import Membri from './routes/membri/Membri';
import Membru from './routes/membru/Membru';
import ScrollToTop from 'react-scroll-to-top';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AdminMembri from './routes/admin/AdminMembri/AdminMembri';
import AdminMembru from './routes/admin/AdminMembru/AdminMembru';
import AdminSlideshow from './routes/admin/AdminSlideshow/AdminSlideshow';
import AdminBlog from './routes/admin/AdminBlog/AdminBlog';

function App() {
  return (
    <div className="App">
      <ScrollToTop smooth color="#ffffff" />

      <Router>
        <Switch>
          {/* <Route path="/termeni-și-condiții">
            <TermsAndConditions />
          </Route>
          <Route path="/users"></Route> */}
          <Route path="/" exact>
            <Root />
          </Route>
          <Route path="/acasă" exact>
            <Root />
          </Route>
          <Route path="/membri" exact>
            <Membri />
          </Route>
          <Route path="/membru/:id" exact component={Membru} />

          {/* <Route path="/salon" exact component={Salon} /> */}
          <Route path="/noutati" exact component={Blog} />

          <Route path="/contact" exact>
            <Contact />
          </Route>

          <Route path="/admin/membri" exact>
            <AdminMembri />
          </Route>
          <Route path="/admin/membru/:id" exact component={AdminMembru} />
          <Route path="/admin/slideshow" exact component={AdminSlideshow} />
          <Route path="/admin/blog" exact component={AdminBlog} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
