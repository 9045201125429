import React, { Component } from 'react';
import './Root.css';

import Constrainter from './../../../global/Constrainter/Constrainter';
import Toolbar from './../../../global/Toolbar/Toolbar';
import SideDrawer from './../../../global/SideDrawer/SideDrawer';
import Backdrop from './../../../global/Backdrop/Backdrop';
import Banner from './../components/Banner/Banner';
import About from './../components/About/About';
import Gallery from './../components/Gallery/Gallery';
import News from './../components/News/News';
import Footer from './../../../global/Footer/Footer';

class Root extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Root">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} page="root" />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <Banner />
        <News />
        <About />
        <Gallery />
        {/* <Contact /> */}
        <Constrainter>
          {/* <SectionTitle title="Doriți să plasați o comandă?" align="center" id="contact" /> */}
          {/* <Contact /> */}
        </Constrainter>
        <Footer />
      </div>
    );
  }
}

export default Root;
