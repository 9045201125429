import React, { Component } from 'react';
import FadeInSection from '../../../../global/FadeInSection/FadeInSection';
import './Gallery.css';
import Flickity from 'react-flickity-component';
import axios from 'axios';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';
require('flickity-imagesloaded');

class Gallery extends Component {
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  state = {
    photos: []
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_slideshow_photos.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ photos: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const flickityOptions = {
      initialIndex: 0,
      wrapAround: true,
      autoPlay: 2000,
      lazyLoad: true,
      imagesLoaded: true
    };
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    return (
      <div className="Gallery" id="gallery">
        <h2>{lang.root.gallery_title}</h2>
        <FadeInSection>
          <div className="flickity-container">
            <Flickity
              className={'carousel'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static={true} // default false
              imagesLoaded={true}
            >
              {this.state.photos.map(photo => (
                <img
                  src={`https://fotoclubproarad.ro/api/${photo.nume_fotografie}`}
                  alt="fotoclubpro arad clubul fotografilor profesionisti"
                />
              ))}
            </Flickity>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default Gallery;
