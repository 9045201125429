import React, { Component } from 'react';
import axios from 'axios';

class BlogContent extends Component {
  state = {
    posts: undefined
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_blog_posts.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ posts: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  componentDidUpdate() {
    let id = window.location.hash.split('#')[1];
    console.log(id);
    if (document.getElementById(id)) document.getElementById(id).scrollIntoView({ block: 'start' });
  }

  reformatDate = dateStr => {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[2] + '.' + dArr[1] + '.' + dArr[0]; //ex out: "18/01/10"
  };

  render() {
    let language;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      language = 'ro';
    } else {
      language = 'eng';
    }
    let posts;
    if (this.state.posts)
      posts = this.state.posts.reverse().map(post => (
        <div className="post">
          <div id={post.id} style={{ height: '8em' }}></div>
          <h2>{language === 'ro' ? post.titlu : post.titlu_eng}</h2>
          <p className="date">{this.reformatDate(post.data)}</p>
          <div className="image">
            <img src={`https://fotoclubproarad.ro/api/${post.nume_fotografie}`} alt={post.titlu} />
          </div>

          <p className="text">{language === 'ro' ? post.text : post.text_eng}</p>
        </div>
      ));
    return (
      <div className="container">
        <h2>{language === 'ro' ? 'Noutăți' : 'News'}</h2>

        <div className="wrapper">
          <div className="left">{posts}</div>

          <div className="right">
            <div
              class="fb-page"
              data-href="https://www.facebook.com/Foto-Club-Pro-Arad-351403434968952"
              data-tabs="timeline"
              data-width=""
              data-height="800"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/Foto-Club-Pro-Arad-351403434968952"
                class="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/Foto-Club-Pro-Arad-351403434968952">Foto Club Pro Arad</a>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogContent;
