import React from 'react';
import './Footer.css';
import FacebookSVG from './../../assets/svgs/facebook';
import InstagramSVG from './../../assets/svgs/instagram';
import ro from './../../ro.json';
import eng from './../../eng.json';

let lang;

if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
  lang = ro;
} else {
  lang = eng;
}

const Footer = () => (
  <footer>
    <div className="title">
      <h1 className="footer-title">FotoClubPro Arad</h1>
    </div>

    <div className="links">
      <div className="column left">
        <a href={`${window.location.protocol}//${window.location.host}/`}>
          <span>{lang.global.nav[0]}</span>
        </a>
        <a href={`${window.location.protocol}//${window.location.host}/membri`}>
          <span>{lang.global.nav[1]}</span>
        </a>
        <a href={`https://salon.fotoclubproarad.ro`}>
          <span>{lang.global.nav[2]}</span>
        </a>
        <a href={`${window.location.protocol}//${window.location.host}/blog`}>
          <span>{lang.global.nav[3]}</span>
        </a>
        <a href={`${window.location.protocol}//${window.location.host}/contact`}>
          <span>{lang.global.nav[4]}</span>
        </a>
      </div>
      <div className="separator" />
      <div className="column mid">
        <div style={{ marginTop: '1em' }} className="icon-marginer" />
        <a
          href="https://www.facebook.com/Foto-Club-Pro-Arad-351403434968952/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FacebookSVG />
        </a>
        <div style={{ marginTop: '1em' }}>
          <a href="/" target="_blank" rel="noreferrer noopener">
            <InstagramSVG />
          </a>
        </div>
      </div>
      <div className="separator" />
      <div className="column right">
        {/* <span>+4074 385 2617</span> */}
        <span>fotoclubproarad@gmail.com</span>
      </div>
    </div>

    <div className="copyright">
      <div className="line" />
      <p>
        {lang.global.footer}
        <a href="https://github.com/robertfedus">Robert Feduș</a>.
      </p>
    </div>
  </footer>
);

export default Footer;
