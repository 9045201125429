import React, { useState, useEffect } from 'react';
import './SideDrawer.css';
// import logo from './../../assets/logo.png';
import roFlag from './../../assets/ro.png';
import ukFlag from './../../assets/uk.png';
import ro from './../../ro.json';
import eng from './../../eng.json';

const SideDrawer = props => {
  const [shouldShow, setShouldShow] = useState();

  useEffect(() => {
    setShouldShow(props.show);
  }, [props]);

  let drawerClasses = 'side-drawer';

  if (shouldShow) {
    drawerClasses = 'side-drawer open';
  }

  const toggleLang = () => {
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang'))
      localStorage.setItem('lang', 'eng');
    else localStorage.setItem('lang', 'ro');
    window.location.reload();
  };

  let flag, lang;

  if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
    flag = ukFlag;
    lang = ro;
  } else {
    flag = roFlag;
    lang = eng;
  }

  return (
    <nav className={drawerClasses}>
      {/* <div className="drawer-logo">
        <img src={logo} alt="" />
      </div> */}
      <div className="drawer-items">
        <a href={`${window.location.protocol}//${window.location.host}/`}>{lang.global.nav[0]}</a>
        <a href={`${window.location.protocol}//${window.location.host}/membri`}>{lang.global.nav[1]}</a>
        <a href={`https://salon.fotoclubproarad.ro`}>{lang.global.nav[2]}</a>
        <a href={`${window.location.protocol}//${window.location.host}/noutati`}>{lang.global.nav[3]}</a>
        <a href={`https://fotoclubproarad.blogspot.com`}>{lang.global.nav[4]}</a>
        <a href={`${window.location.protocol}//${window.location.host}/contact`}>{lang.global.nav[5]}</a>

        <img
          src={flag}
          alt="Photography international arad fotografie vernisaj"
          style={{
            height: '2em',
            cursor: 'pointer',
            display: 'block',
            zIndex: '2'
          }}
          onClick={toggleLang}
        />
      </div>
    </nav>
  );
};

export default SideDrawer;
