import React, { Component } from 'react';
import './AdminMembri.css';

import axios from 'axios';

class AdminMembri extends Component {
  state = {
    members: undefined,
    nume: '',
    titlu_onorific_ro: '',
    titlu_onorific_eng: '',
    descriere_ro: '',
    descriere_eng: '',
    rol: '',
    selectedPortraitFile: undefined,
    selectedBigFile: undefined,
    password: ''
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_members.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ members: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  uploadMember = e => {
    e.preventDefault();
    if (this.state.rol === '') return alert('Alegeți un rol!');
    if (!this.state.selectedPortraitFile) return alert('Întâi încărcați ambele fotografii!');
    if (!this.state.selectedBigFile) return alert('Întâi încărcați ambele fotografii!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie_portret', this.state.selectedPortraitFile);
    data.append('fotografie_mare', this.state.selectedBigFile);
    data.append('nume', this.state.nume);
    data.append('titlu_onorific_ro', this.state.titlu_onorific_ro);
    data.append('titlu_onorific_eng', this.state.titlu_onorific_eng);
    data.append('descriere_ro', this.state.descriere_ro);
    data.append('descriere_eng', this.state.descriere_eng);
    data.append('rol', this.state.rol);
    axios
      .post(`https://fotoclubproarad.ro/api/upload_member.php?admin_pass=${this.state.password}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');

        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  portraitFileChanged = event => {
    this.setState({
      selectedPortraitFile: event.target.files[0]
    });
  };

  bigFileChanged = event => {
    this.setState({
      selectedBigFile: event.target.files[0]
    });
  };

  render() {
    let names;
    if (this.state.members)
      names = this.state.members.map(member => (
        <a href={`/admin/membru/${member.id}`} className="member-link">
          {member.nume}
        </a>
      ));

    return (
      <div className="AdminMembri">
        <p className="honor-title">
          Introdu parola de administrator:
          <br />
          <input onChange={this.changeHandler} name="password" type="password" />
        </p>
        <h3 style={{ paddingLeft: '5em', paddingRight: '5em' }}>
          Bine ați venit pe pagina de administrator a membrilor. Apăsați pe un membru pentru a vedea
          informațiile sale sau completați formularul de mai jos pentru a adăuga un membru.
        </h3>

        <div className="form-container">
          <form onSubmit={this.uploadMember}>
            <input
              type="text"
              placeholder="Nume"
              name="nume"
              onChange={this.changeHandler}
              required
              value={this.state.nume}
            />
            <input
              type="text"
              placeholder="Titlu onorific în limba română"
              name="titlu_onorific_ro"
              onChange={this.changeHandler}
              // required
              value={this.state.titlu_onorific_ro}
            />
            <input
              type="text"
              placeholder="Titlu onorific în limba engleză"
              name="titlu_onorific_eng"
              onChange={this.changeHandler}
              // required
              value={this.state.titlu_onorific_eng}
            />
            <textarea
              type="text"
              placeholder="Descriere în limba română"
              name="descriere_ro"
              onChange={this.changeHandler}
              value={this.state.descriere_ro}
              required
            />
            <textarea
              type="text"
              placeholder="Descriere în limba engleză"
              name="descriere_eng"
              onChange={this.changeHandler}
              value={this.state.descriere_eng}
              required
            />

            <select name="rol" onChange={this.changeHandler} value={this.state.rol}>
              <option value="" selected>
                Selectează rolul
              </option>
              <option value="membru">Membru FotoClubPro</option>

              <option value="fondator">Membru fondator</option>
              <option value="onoare">Membru de onoare</option>
              <option value="colaborator">Membru colaborator</option>
              <option value="memoriam">In Memoriam</option>
            </select>

            <label>Încarcă o fotografie-portret</label>
            <input type="file" onChange={this.portraitFileChanged} />

            <label>Încarcă o fotografie mai mare care va fi afișată pe pagina membrului</label>
            <input type="file" onChange={this.bigFileChanged} />

            <button type="submit" className="submit-button">
              Trimite
            </button>
          </form>
        </div>
        {names}
      </div>
    );
  }
}

export default AdminMembri;
