import React from 'react';
import ro from './../../ro.json';
import eng from './../../eng.json';
let lang;
if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
  lang = ro;
} else {
  lang = eng;
}

const links = lang.global.nav.map(el => el);

const Links = links.map(link => {
  let to = `${window.location.protocol}//${window.location.host}/${link.toLowerCase()}`;
  if (to === `${window.location.protocol}//${link.toLowerCase()}/contact`)
    to = `http://${link.toLowerCase()}/contact`;
  if (link === 'Acasă' || link === 'Home') to = `${window.location.protocol}//${window.location.host}`;
  if (link === 'Members') to = `${window.location.protocol}//${window.location.host}/membri`;
  if (link === 'Noutăți' || link === 'News') to = `${window.location.protocol}//${window.location.host}/noutati`;
  if (link === 'Salon' || link === 'Contest') to = `https://salon.fotoclubproarad.ro`;
  if (link === 'Blog') to = `https://fotoclubproarad.blogspot.com`;

  return (
    <li key={link}>
      <a href={to}>{link}</a>
    </li>
  );
});

export default Links;
