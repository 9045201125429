import React, { Component } from 'react';

import axios from 'axios';

class AdminBlog extends Component {
  state = {
    posts: undefined,
    titlu: '',
    titlu_eng: '',
    data: '',
    text: '',
    fotografie: undefined,
    password: '',
    text_eng: ''
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_blog_posts.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ posts: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  uploadMember = e => {
    e.preventDefault();
    if (this.state.data === '') return alert('Alegeți data!');
    if (!this.state.fotografie) return alert('Întâi încărcați o fotografie!');
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('fotografie', this.state.fotografie);
    data.append('titlu', this.state.titlu);
    data.append('titlu_eng', this.state.titlu_eng);
    data.append('data', this.state.data);
    data.append('text', this.state.text);
    data.append('text_eng', this.state.text_eng);

    axios
      .post(`https://fotoclubproarad.ro/api/upload_blog_post.php?admin_pass=${this.state.password}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        this.response = { ...response };
        if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');

        alert('Succes!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  photoFileChanged = event => {
    this.setState({
      fotografie: event.target.files[0]
    });
  };

  deletePost = id => {
    let r = window.confirm('Sigur doriți să ștergeți această postare? Acțiunea este ireversibilă!');
    if (r === true) {
      axios
        .get(
          `https://fotoclubproarad.ro/api/delete_blog_post.php?id_postare=${id}&admin_pass=${this.state.password}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Client-ID aa4f70aa066eefe'
            }
            // Authorization: 'Bearer ' + varToken
          }
        )
        .then(response => {
          this.response = { ...response };
          if (response.data === 'Invalid credentials.') return alert('Parolă incorectă');
          // window.location.href = '/projects';
          alert('Succes!');
          window.location.reload();
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  render() {
    let posts;
    if (this.state.posts)
      posts = this.state.posts.reverse().map(post => (
        <div style={{ padding: '3em', marginTop: '2em', paddingTop: '0' }}>
          <h4 style={{ fontSize: '22px' }}>{post.titlu}</h4>
          <p style={{ marginTop: '0.5em' }}>{post.data}</p>
          <img
            src={`https://fotoclubproarad.ro/api/${post.nume_fotografie}`}
            style={{ height: '23em', marginTop: '2em' }}
            alt={post.titlu}
          />
          <span
            style={{ color: 'red', display: 'block', cursor: 'pointer' }}
            onClick={() => this.deletePost(post.id)}
          >
            Ștergeți postarea
          </span>
          <p style={{ whiteSpace: 'pre-wrap', marginTop: '2em', lineHeight: '1.8' }}>{post.text}</p>
          <br />
          <br />
          <p style={{ whiteSpace: 'pre-wrap', marginTop: '2em', lineHeight: '1.8' }}>{post.text_eng}</p>
        </div>
      ));

    return (
      <div className="AdminMembri">
        <p className="honor-title">
          Introdu parola de administrator:
          <br />
          <input onChange={this.changeHandler} name="password" type="password" />
        </p>
        <h3 style={{ paddingLeft: '5em', paddingRight: '5em' }}>
          Bine ați venit pe pagina de administrator a blog-ului. Aici puteți adăuga o postare folosind
          formularul de mai jos.
        </h3>

        <div className="form-container">
          <form onSubmit={this.uploadMember}>
            <input
              type="text"
              placeholder="Titlu în limba română"
              name="titlu"
              onChange={this.changeHandler}
              required
            />
            <input
              type="text"
              placeholder="Titlu în limba engleză"
              name="titlu_eng"
              onChange={this.changeHandler}
              required
            />
            <label>Selectați data evenimentului</label>
            <input type="date" name="data" onChange={this.changeHandler} />
            <textarea
              type="text"
              placeholder="Textul postării în limba română"
              name="text"
              onChange={this.changeHandler}
              required
            />

            <textarea
              type="text"
              placeholder="Textul postării în limba engleză"
              name="text_eng"
              onChange={this.changeHandler}
              required
            />
            <label>Încarcă o fotografie pentru postare</label>
            <input type="file" onChange={this.photoFileChanged} />
            <button type="submit" className="submit-button">
              Trimite
            </button>
          </form>
        </div>
        <br />
        <br />
        <br />
        {posts}
      </div>
    );
  }
}

export default AdminBlog;
