import React, { Component } from 'react';
import './Members.css';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';
import axios from 'axios';

class Members extends Component {
  state = {
    members: []
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_members.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ members: response.data });
        console.log(this.state.members);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let lang, language;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
      language = 'ro';
    } else {
      lang = eng;
      language = 'eng';
    }
    let fondatori = '',
      membri = '',
      membriOnoare = '',
      membriColaboratori = '',
      memoriam = '';

    fondatori = this.state.members.map((member, index) => {
      if (member.rol === 'fondator') {
        return (
          <div className="image">
            <a href={`/membru/${member.id}`}>
              <img
                src={`https://fotoclubproarad.ro/api/${this.state.members[index].fotografie_portret}`}
                alt="fotografie arad"
              />
              <div className="caption">
                {member.nume}
                <br />
                {language === 'ro' ? member.titlu_onorific_ro : member.titlu_onorific_eng}
              </div>
            </a>
          </div>
        );
      }
      return '';
    });

    membri = this.state.members.map((member, index) => {
      if (member.rol === 'membru') {
        return (
          <div className="image">
            <a href={`/membru/${member.id}`}>
              <img
                src={`https://fotoclubproarad.ro/api/${this.state.members[index].fotografie_portret}`}
                alt="fotografie arad pro club"
              />
              <div className="caption">
                {member.nume}
                <br />
                {language === 'ro' ? member.titlu_onorific_ro : member.titlu_onorific_eng}
              </div>
            </a>
          </div>
        );
      }
      return '';
    });

    membriOnoare = this.state.members.map((member, index) => {
      if (member.rol === 'onoare') {
        return (
          <div className="image">
            <a href={`/membru/${member.id}`}>
              <img
                src={`https://fotoclubproarad.ro/api/${this.state.members[index].fotografie_portret}`}
                alt="fotografie fotoclubpro arad"
              />
              <div className="caption">
                {member.nume}
                <br />
                {language === 'ro' ? member.titlu_onorific_ro : member.titlu_onorific_eng}
              </div>
            </a>
          </div>
        );
      }
      return '';
    });

    membriColaboratori = this.state.members.map((member, index) => {
      if (member.rol === 'colaborator') {
        return (
          <div className="image">
            <a href={`/membru/${member.id}`}>
              <img
                src={`https://fotoclubproarad.ro/api/${this.state.members[index].fotografie_portret}`}
                alt="fotoclubpro arad"
              />
              <div className="caption">
                {member.nume}
                <br />
                {language === 'ro' ? member.titlu_onorific_ro : member.titlu_onorific_eng}
              </div>
            </a>
          </div>
        );
      }
      return '';
    });

    memoriam = this.state.members.map((member, index) => {
      if (member.rol === 'memoriam') {
        return (
          <div className="image">
            <a href={`/membru/${member.id}`}>
              <img
                src={`https://fotoclubproarad.ro/api/${this.state.members[index].fotografie_portret}`}
                alt="pro foto club arad fotografi profesionisti"
              />
              <div className="caption">
                {member.nume}
                <br />
                {language === 'ro' ? member.titlu_onorific_ro : member.titlu_onorific_eng}
              </div>
            </a>
          </div>
        );
      }
      return '';
    });

    return (
      // SE SARE PE RAND NOU DUPA 3 IMAGINI (3 MEMBRI)
      <div className="Members">
        <div className="section first">
          <h2 className="section-title">{lang.members.founders_title}</h2>
          <div className="images">{fondatori}</div>
        </div>

        <div className="section">
          <h2 className="section-title">{lang.members.members_title}</h2>
          <div className="images">{membri}</div>
        </div>

        <div className="section">
          <h2 className="section-title">{lang.members.honorary_title}</h2>
          <div className="images">{membriOnoare}</div>
        </div>

        <div className="section">
          <h2 className="section-title">{lang.members.colab_title}</h2>
          <div className="images">{membriColaboratori}</div>
        </div>

        <div className="section">
          <h2 className="section-title">{lang.members.memoriam_title}</h2>
          <div className="images">{memoriam}</div>
        </div>
      </div>
    );
  }
}

export default Members;
