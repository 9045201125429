import React, { Component } from 'react';
import FadeInSection from '../../../../global/FadeInSection/FadeInSection';
import './News.css';
import axios from 'axios';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';

class News extends Component {
  state = {
    news: undefined
  };

  componentDidMount = () => {
    axios
      .get('https://fotoclubproarad.ro/api/get_blog_posts.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ news: response.data.reverse() });
      })
      .catch(e => {
        console.log(e);
      });
  };

  reformatDate = dateStr => {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[2] + '.' + dArr[1] + '.' + dArr[0]; //ex out: "18/01/10"
  };

  render() {
    let lang, title1, title2, text1, text2;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
      title1 = this.state.news !== undefined ? this.state.news[0].titlu : '';
      title2 = this.state.news !== undefined ? this.state.news[1].titlu : '';
      text1 = this.state.news !== undefined ? this.state.news[0].text.substring(0, 230) + '...' : '';
      text2 = this.state.news !== undefined ? this.state.news[1].text.substring(0, 230) + '...' : '';
    } else {
      lang = eng;
      title1 = this.state.news !== undefined ? this.state.news[0].titlu_eng : '';
      title2 = this.state.news !== undefined ? this.state.news[1].titlu_eng : '';
      text1 = this.state.news !== undefined ? this.state.news[0].text_eng.substring(0, 230) + '...' : '';
      text2 = this.state.news !== undefined ? this.state.news[1].text_eng.substring(0, 230) + '...' : '';
    }
    // let news;
    // if (this.state.news) news = this.state.news.reverse();

    return (
      <div className="News">
        <div className="spacer" id="s" />
        <h2 className="section-title">{lang.root.news_title}</h2>

        <FadeInSection>
          <div className="container">
            <div className="left">
              <div className="left-container">
                <div className="image">
                  <a href={`/noutati#${this.state.news !== undefined ? this.state.news[0].id : ''}`}>
                    <img
                      src={`https://fotoclubproarad.ro/api/${
                        this.state.news !== undefined ? this.state.news[0].nume_fotografie : ''
                      }`}
                      alt="noutati fotografi arad fotografii noi de la salonul de fotografie"
                    />
                  </a>
                </div>

                <div className="text-container">
                  <h3 className="new-title">{title1}</h3>
                  <p className="text">
                    <span className="date">
                      {this.state.news !== undefined ? this.reformatDate(this.state.news[0].data) : ''}
                    </span>
                    <br />
                    <br />
                    {text1}
                  </p>

                  <a href={`/noutati#${this.state.news !== undefined ? this.state.news[0].id : ''}`}>
                    <button className="read-more">{lang.root.news_button}</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="left right">
              <div className="left-container">
                <div className="image">
                  <a href={`/noutati#${this.state.news !== undefined ? this.state.news[1].id : ''}`}>
                    <img
                      src={`https://fotoclubproarad.ro/api/${
                        this.state.news !== undefined ? this.state.news[1].nume_fotografie : ''
                      }`}
                      alt=""
                    />
                  </a>
                </div>

                <div className="text-container">
                  <h3 className="new-title">{title2}</h3>
                  <p className="text">
                    <span className="date">
                      {this.state.news !== undefined ? this.reformatDate(this.state.news[1].data) : ''}
                    </span>
                    <br />
                    <br />
                    {text2}
                  </p>

                  <a href={`/noutati#${this.state.news !== undefined ? this.state.news[1].id : ''}`}>
                    <button className="read-more">{lang.root.news_button}</button>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="right">
            <div className="new">
              <div className="right-container">
                <div className="image">
                  <img src={img2} alt="" />
                </div>
              </div>
            </div>
            <div className="new">
              <div className="right-container">abc</div>
            </div>
          </div> */}
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default News;
