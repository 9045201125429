import React, { Component } from 'react';
import Form from './components/Form/Form';
import Toolbar from './../../global/Toolbar/Toolbar';
import SideDrawer from './../../global/SideDrawer/SideDrawer';
import Backdrop from './../../global/Backdrop/Backdrop';
// import Constrainter from './../../global/Constrainter/Constrainter';
import Footer from './../../global/Footer/Footer';

class Contact extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Contact">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />

        <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
        {backdrop}
        <Form />

        <Footer />
      </div>
    );
  }
}

export default Contact;
