import React, { Component } from 'react';
import './MemberInfo.css';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

import axios from 'axios';

class MemberInfo extends Component {
  state = {
    member: '',
    photos: []
  };

  componentDidMount = () => {
    axios
      .get(`https://fotoclubproarad.ro/api/get_member.php?id=${this.props.id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ member: response.data[0] });
        // console.log(this.state.member);
      })
      .catch(e => {
        console.log(e);
      });

    // Get all photos corresponding to the member with the request below

    axios
      .get(`https://fotoclubproarad.ro/api/get_member_photos.php?id_membru=${this.props.id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ photos: response.data });
        // console.log(this.state.photos);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let language;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      language = 'ro';
    } else {
      language = 'eng';
    }

    const options = {
      thumbnails: {
        thumbnailsAlignment: 'center',
        thumbnailsGap: '0 1px'
      },
      buttons: {
        showDownloadButton: false
      }
    };
    return (
      <div className="MemberInfo">
        {/* <h2 className="section-title">{this.state.member.nume}</h2> */}
        <div className="container">
          <div className="image">
            <img
              src={`https://fotoclubproarad.ro/api/${this.state.member.fotografie_mare}`}
              alt="fotoclubproarad"
            />
          </div>

          <p className="text">
            <h2 className="section-title">{this.state.member.nume}</h2>

            {language === 'ro' ? this.state.member.descriere_ro : this.state.member.descriere_eng}
          </p>
        </div>
        <div className="gallery">
          {/* <Gallery images={IMAGES} rowHeight={150} /> */}
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {this.state.photos.map(photo => {
                return (
                  <a href={`https://fotoclubproarad.ro/api/${photo.nume_fotografie}`}>
                    <img
                      src={`https://fotoclubproarad.ro/api/${photo.nume_fotografie}`}
                      alt=""
                      className="thumbnail"
                    />
                  </a>
                );
              })}
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div style={{ clear: 'left', marginBottom: '5em' }}></div>
      </div>
    );
  }
}

export default MemberInfo;
