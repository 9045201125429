import React, { Component } from 'react';
import axios from 'axios';
import './Form.css';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';

class Form extends Component {
  state = {
    nume: '',
    prenume: '',
    email: '',
    telefon: '',
    mesaj: ''
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = event => {
    event.preventDefault();
    const reqBody = { ...this.state };
    axios
      .post('https://fotoclubproarad.ro/api/contact_form.php', reqBody)
      .then(response => {
        this.response = { ...response };
        var r = window.confirm('Success!');
        if (r === true) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      })
      .catch(e => {
        alert('Error sending the message');
      });
  };

  render() {
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    const { nume, prenume, email, telefon, mesaj } = this.state;

    return (
      <div className="contact-form">
        {/* <h2 className="section-title" id="contact" style={{ paddingTop: '5em' }}>
          Contactați-ne
        </h2> */}
        <p className="details">
          {lang.contact.contact_text}
          <b>fotoclubproarad@gmail.com</b>.
        </p>

        <div className="form-container">
          <form className="contact-form" onSubmit={this.submitHandler}>
            <div className="row">
              <input
                type="text"
                placeholder={lang.contact.form.last_name}
                name="nume"
                onChange={this.changeHandler}
                required
                value={nume}
              />
              <input
                type="text"
                placeholder={lang.contact.form.first_name}
                name="prenume"
                onChange={this.changeHandler}
                required
                value={prenume}
              />
            </div>

            <div className="row">
              <input
                type="email"
                placeholder={lang.contact.form.email}
                name="email"
                onChange={this.changeHandler}
                required
                value={email}
              />
              <input
                type="phone"
                placeholder={lang.contact.form.phone}
                name="telefon"
                onChange={this.changeHandler}
                required
                value={telefon}
              />
            </div>

            <textarea
              type="text"
              name="mesaj"
              className="long"
              placeholder={lang.contact.form.message}
              onChange={this.changeHandler}
              required
              value={mesaj}
            />

            <button type="submit" className="submit-button">
              {lang.contact.form.submit}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Form;
