import DrawerToggleButton from './../SideDrawer/DrawerToggleButton';
import './Toolbar.css';
import logo from './../../assets/logo.png';
import Links from './Elements';
import Zoom from 'react-reveal/Zoom';
import React, { Component } from 'react';
import roFlag from './../../assets/ro.png';
import ukFlag from './../../assets/uk.png';

class Toolbar extends Component {
  state = {
    scrolled: false
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 200;
      if (!isTop) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  }

  toggleLang = () => {
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang'))
      localStorage.setItem('lang', 'eng');
    else localStorage.setItem('lang', 'ro');
    window.location.reload();
  };

  render() {
    const { scrolled } = this.state;
    let toolbarClasses = 'toolbar';
    if (scrolled) toolbarClasses += ' scrolled';
    if (!this.props.page) toolbarClasses += ' non-root-toolbar';
    let flag;

    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) flag = ukFlag;
    else flag = roFlag;

    return (
      <header className={toolbarClasses} id="toolbar">
        <div className="inner-toolbar">
          <div className="spacer-container hamburger-spacer">
            <div className="toolbar-toggle-button">
              <DrawerToggleButton click={this.props.drawerClickHandler} />
            </div>
          </div>
          <div className="spacer-container title-spacer">
            <a href="/">
              <Zoom>
                <h1 className="title">FotoClubPro Arad</h1>
              </Zoom>
            </a>
          </div>
          <div className="spacer-container spacer-container-center">
            <div className="toolbar-navigation-items">
              <ul>
                {Links}
                <img
                  src={flag}
                  alt="Photography international arad fotografie vernisaj"
                  style={{
                    height: '2em',
                    marginLeft: '1em',
                    cursor: 'pointer',
                    display: 'block',
                    zIndex: '2'
                  }}
                  onClick={this.toggleLang}
                />
              </ul>
            </div>
          </div>
          <div className="spacer-container spacer-container-logo">
            <a href="/">
              <Zoom>
                <img
                  src={logo}
                  alt="fotograf pro club arad fotografie salon concurs poze poza alb negru color"
                  className="logo"
                />
              </Zoom>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Toolbar;
