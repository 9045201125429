import React from 'react';
import './Banner.css';
// import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import video from './../../../../assets/clip.mp4';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';

let lang;
if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
  lang = ro;
} else {
  lang = eng;
}

const Banner = () => {
  return (
    <div className="Banner">
      <div className="container">
        {/* <Particles
          params={{
            particles: {
              color: {
                value: '#fff'
              },
              number: {
                value: 20,
                density: {
                  enable: false
                }
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  speed: 4,
                  size_min: 0.3
                }
              },
              line_linked: {
                enable: false
              },
              move: {
                random: true,
                speed: 2,
                direction: 'top',
                out_mode: 'out'
              }
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse'
                }
              },
              modes: {
                bubble: {
                  distance: 250,
                  duration: 2,
                  size: 0,
                  opacity: 0
                },
                repulse: {
                  distance: 100,
                  duration: 4
                }
              }
            }
          }}
        /> */}
        {/* <img
          className="media"
          src={gif}
          alt=" salon blog contact fotoclubpro arad fotograf camera foto profesionala fotografi profesionisti membri fotografie alb negru color"
        /> */}
        <video className="media" preload="auto" autoPlay muted loop playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="intro">
          <h3>{lang.root.banner_text}</h3>
          {/* <p>Clubul celor mai experimentați fotografi ai Aradului</p> */}
        </div>

        <a className="arrow" href="#s">
          {' '}
          <FontAwesomeIcon icon={faChevronDown} size="2x" className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Banner;
