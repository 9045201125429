import React from 'react';
import './About.css';
import FadeInSection from '../../../../global/FadeInSection/FadeInSection';
import ro from './../../../../ro.json';
import eng from './../../../../eng.json';

// import img1 from './../../../../assets/photo1.jpeg';
// import img3 from './../../../../assets/photo3.jpeg';

const About = props => {
  let lang;
  if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
    lang = ro;
  } else {
    lang = eng;
  }

  return (
    <div className="About" id="about">
      <h2 className="section-title">{lang.root.about_title}</h2>

      <FadeInSection>
        <div className="wrapper">
          <p className="text" style={{ textAlign: 'justify', textAlignLast: 'center', marginTop: '4em' }}>
            {lang.root.about_text1}
            <br />
            <br />
            {lang.root.about_text2}
            <br />
            <br />
            {lang.root.about_text3}
          </p>
          {/* <img src={img1} alt="fotoclubpro arad fotografie profesionala" /> */}
        </div>

        {/* <div className="section top">
          <div className="container">
            <img src={img1} alt="" />
            <div className="bottom-right">
              <p>
                Hey! Bine ai venit pe site-ul meu. Eu sunt Călin Andra, un pasionat de fotografie care a făcut
                din hobby o profesie. Sunt stabilit în Arad, dar pentru a face fotografii sunt dispus să
                călătoresc.
              </p>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <img src={img3} alt="" />
            <div className="bottom-right">
              <p>
                Hey! Bine ai venit pe site-ul meu. Eu sunt Călin Andra, un pasionat de fotografie care a făcut
                din hobby o profesie. Sunt stabilit în Arad, dar pentru a face fotografii sunt dispus să
                călătoresc.
              </p>
            </div>
          </div>
        </div> */}
      </FadeInSection>
    </div>
  );
};

export default About;
