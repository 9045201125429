import React, { Component } from 'react';
import Members from './components/Members/Members';
import Toolbar from './../../global/Toolbar/Toolbar';
import SideDrawer from './../../global/SideDrawer/SideDrawer';
import Backdrop from './../../global/Backdrop/Backdrop';
import Footer from './../../global/Footer/Footer';

class Membri extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Membri">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />

        <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
        {backdrop}
        <Members />

        <Footer />
      </div>
    );
  }
}

export default Membri;
